<template>
  <div class="mobile">
    <div class="container1">
      <div class="content">
        <div class="top-box">
          <img src="/imgs/home/logo.png" alt="" class="logo">
          <div class="top-but" @click="popup = !popup">
            <div class="but-line" />
            <div class="but-line" />
            <div class="but-line" />
            <div v-show="popup" class="popup">
              <div class="popup-item" @click="goPage('/mobileAboutus')">关于无界SaaS</div>
              <div class="popup-item"><a
                href="http://unih5.wujieuni.com/newh5/#/download"
                target="view_window"
              >下载无界SaaS</a></div>
              <div class="popup-item"><a href="#codeList">关注公众号</a></div>
            </div>
          </div>
        </div>
        <div class="box">
          <img src="/imgs/home/logo.png" alt="" class="logo">
          <img src="/imgs/home/container1-text.png" alt="" class="container1-text-img">
          <div class="container1-text">
            <img src="/imgs/mobileHome/container1-text.png" alt="">
          </div>
        </div>
        <img
          src="/imgs/home/数字一卡通.png"
          alt=""
          class="twinkle twinkle1"
        >
        <img
          src="/imgs/home/生态云仓.png"
          alt=""
          class="twinkles twinkle2"
        >
        <img
          src="/imgs/home/家居云仓.png"
          alt=""
          class="twinkle twinkle3"
        >
        <img
          src="/imgs/home/综合云仓.png"
          alt=""
          class="twinkle twinkle5"
        >
        <img
          src="/imgs/home/GlobalLink.png"
          alt=""
          class="twinkles twinkle6"
        >
        <img
          src="/imgs/home/omyo.png"
          alt=""
          class="twinkle twinkle7"
        >
        <div class="stars stars1" />
        <div class="stars stars2" />
        <div class="stars stars4" />
        <div class="stars stars6" />
        <div class="stars stars7" />
        <div class="stars stars8" />
        <div class="stars stars10" />
        <div class="stars stars11" />
        <div class="stars stars12" />
        <div class="stars stars13" />
        <div class="stars stars14" />
      </div>
    </div>
    <div class="container2">
      <img src="/imgs/mobileHome/titleimg1.png" alt="" class="titleimg">
      <img src="/imgs/home/container2-img.png" alt="">
      <img src="/imgs/mobileHome/container2-text1.png" alt="" class="container2-text">
      <a
        href="http://unih5.wujieuni.com/newh5/#/download"
        target="view_window"
      >
        <div class="but">了解更多</div>
      </a>
      <img src="/imgs/mobileHome/titleimg2.png" alt="" class="titleimg">
      <img src="/imgs/home/container2-img1.png" alt="">
      <img src="/imgs/mobileHome/container2-text2.png" alt="" class="container2-text">
      <a href="http://www.uni02.com/" target="view_window">
        <div class="but">了解更多</div>
      </a>
    </div>
    <div class="container3">
      <img src="/imgs/mobileHome/container3-bg.jpg" alt="" class="bg">
      <div class="box">
        <img src="/imgs/mobileHome/container3-title.png" alt="">
        <p>打破边界，寻找志同道合的你，
          构建利益共同体、事业共同体、命运共同体，做更好的自己！
          共同实现自我价值，同时创造出独一无二的价值，
          获得无边界利润来源，共同实现无边界经营创业！</p>
      </div>
    </div>
    <div class="container4">
      <img src="/imgs/home/container4-img1.png" alt="" class="titleimg">
      <div class="newsList">
        <div v-for="i in articleList" :key="i.articleId" class="list-item" @click="news('https://unih5.wujieuni.com/newh5/#/news/details?articleId='+i.articleId)">
          <div class="img-box">
            <img :src="i.titleImageUrl" alt>
          </div>
          <div class="item-box">
            <div class="time">{{ i.createTime }}</div>
            <div class="title">{{ i.title }}</div>
          </div>
        </div>
      </div>
      <a href="#codeList"><div class="but">了解更多</div></a>
    </div>
    <div class="container5">
      <div class="linkList">
        <div class="linkItem" @click="goPage('/mobileAboutus')">
          关于我们
          <span>></span>
        </div>
        <div class="linkItem" @click="goPage('/mobilejoinus')">
          招贤纳士
          <span>></span>
        </div>
        <div class="linkItem" @click="goPage('/mobileContactus')">
          联系我们
          <span>></span>
        </div>
        <div class="linkItem" @click="goPage('/mobileprivacy')">
          法律信息
          <span>></span>
        </div>
      </div>
      <div class="box">
        <img src="/imgs/mobileHome/bottom-logo.png" alt="" class="logo">
        <div id="codeList" class="codeList">
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem1.png"
              alt=""
            >
            <div class="code-text">无界SaaS公众号</div>
          </div>
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem2.jpg"
              alt=""
            >
            <div class="code-text">Uni无界云仓公众号</div>
          </div>
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem3.jpg"
              alt=""
            >
            <div class="code-text">无界SaaS安卓端</div>
          </div>
          <div class="codeItem">
            <img
              src="/imgs/home/codeItem4.png"
              alt=""
            >
            <div class="code-text">无界SaaS苹果端</div>
          </div>
        </div>
        <div class="recordNo">
          Copyright ©2014-2021 UNI02.COM All Rights Reserved
          <a
            href="https://beian.miit.gov.cn/?spm=a2cmq.17629970.J_9220772140.110.f0d079fewWqZNB#/Integrated/index"
            target="_blank"
          >粤ICP备2023005663号-1</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap/all'
import axios from 'axios'
export default {
  data() {
    return {
      popup: false,
      articleList: []
    }
  },
  created() {
    window.scrollTo(0, 0)
    axios.get('//uniapi.wujieuni.com/v1/shareArticle/list?categoryId=19&page=1&pageSize=3').then((res) => {
      this.articleList = res.data.body.page.list
    }).catch((error) => {
      console.log(error)
    })
  },
  mounted() {
    gsap.to('.twinkle', {
      duration: 2,
      scale: 'random(0.7,1)',
      repeat: -1,
      yoyo: true,
      opacity: 1
    })
    setTimeout(() => {
      gsap.to('.twinkles', {
        duration: 2,
        scale: 'random(0.7,1)',
        repeat: -1,
        yoyo: true,
        opacity: 1
      })
      gsap.to('.stars', {
        duration: 2,
        scale: 'random(0.7,1)',
        repeat: -1,
        yoyo: true,
        opacity: 1
      })
    }, 1000)
  },
  methods: {
    goPage(path, key) {
      this.$router.push({
        path: path
      })
    },
    news(link) {
      window.open(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile {
  width: 100%;
  font-size: 14px;
  overflow-x: hidden;
  .container1 {
    position: relative;
    background-color: #002fa7;
    height: calc(100vh);
    .content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .top-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.15rem 0.2rem;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        .logo {
          width: 0.5rem;
        }
        .top-but {
          position: relative;
          .but-line {
            width: 0.22rem;
            height: 3px;
            background-color: #fff;
            margin-top: 0.05rem;
            border-radius: 50px;
          }
          .popup {
            position: absolute;
            top: 40px;
            right: -3px;
            background-color: #f8f8f8;
            border-radius: 6px;
            z-index: 2;
            .popup-item {
              padding: 0.15rem 0.15rem;
              width: 100px;
              a {
                color: #333;
                text-decoration: none;
              }
            }
            &::after {
              content: "";
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #f8f8f8;
              width: 0;
              height: 0;
              position: absolute;
              top: -8px;
              right: 7px;
            }
          }
        }
      }
      .box {
        color: #fff;
        padding: 0 8%;
        .container1-text-img {
          width: 100%;
          margin: 0.3rem 0;
          margin-left: 28px;
          margin-bottom: 0.1rem;
        }
        .container1-text {
          img {
            width: 100%;
          }
        }
      }
      .twinkle1 {
        width: 0.4rem;
        position: absolute;
        left: 0.3rem;
        top: 28%;
      }
      .twinkle2 {
        width: 1rem;
        position: absolute;
        left: 0.6rem;
        top: 10%;
      }
      .twinkle3 {
        width: 0.7rem;
        position: absolute;
        right: 0.6rem;
        top: 6%;
      }
      .twinkle5 {
        width: 1.7rem;
        position: absolute;
        left: 0.1rem;
        bottom: 6%;
      }
      .twinkle6 {
        width: 1.1rem;
        position: absolute;
        right: 0.4rem;
        bottom: 2%;
      }
      .twinkle7 {
        width: 0.37rem;
        position: absolute;
        right: 0.1rem;
        bottom: 16%;
      }
    }
    .stars {
      width: 3px;
      height: 3px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0 0 4px 1px #fff;
      position: absolute;
    }
    .stars1 {
      left: 20%;
      top: 3%;
      width: 2px;
      height: 2px;
    }
    .stars2 {
      right: 20%;
      top: 5%;
    }
    .stars4 {
      left: 7%;
      top: 16%;
    }
    .stars6 {
      left: 37%;
      top: 19%;
      width: 1px;
      height: 1px;
    }
    .stars7 {
      right: 17%;
      top: 23%;
    }
    .stars8 {
      left: 27%;
      top: 29%;
      width: 1px;
      height: 1px;
    }
    .stars10 {
      left: 13%;
      bottom: 4%;
      width: 2px;
      height: 2px;
    }
    .stars11 {
      left: 38%;
      bottom: 23%;
    }
    .stars12 {
      right: 29%;
      bottom: 15%;
      width: 1px;
      height: 1px;
    }
    .stars13 {
      right: 11%;
      bottom: 8%;
    }
    .stars14 {
      right: 12%;
      bottom: 28%;
    }
  }
  .container2 {
    padding: 0.15rem 0;
    padding-bottom: 0.4rem;
    text-align: center;
    & > img {
      width: 80%;
    }
    .titleimg {
      width: 70%;
      margin: 0.4rem auto;
      display: block;
    }
    .but {
      color: #fff;
      padding: 0.05rem 0.3rem;
      border-radius: 50px;
      background-color: #002fa7;
      display: inline-block;
      margin-top: 0.4rem;
    }
    & > p {
      color: #808080;
      line-height: 0.3rem;
    }
    .container2-text {
      margin-top: 0.4rem;
      width: 90%;
    }
  }
  .container3 {
    position: relative;
    display: flex;
    align-items: center;
    .bg {
      width: 100%;
    }
    .box {
      position: absolute;
      color: #fff;
      padding: 0 10%;
      & > img {
        width: 75%;
      }
      & > p {
        line-height: 0.3rem;
        margin-top: 0.3rem;
      }
    }
  }
  .container4 {
    padding: 0 10%;
    padding-bottom: 0.3rem;
    .titleimg {
      width: 70%;
      margin-top: 0.3rem;
    }
    .newsList {
      img {
        width: 100%;
      }
      .list-item {
        margin-top: 0.3rem;
        text-align: left;
        line-height: 0.25rem;
        .time {
          color: #7d7d7d;
        }
      }
    }
    .but {
      color: #fff;
      padding: 0.05rem 0.3rem;
      border-radius: 50px;
      background-color: #002fa7;
      display: inline-block;
      margin-top: 0.2rem;
    }
  }
  .container5 {
    background-color: #002fa7;
    color: #fff;
    .linkList {
      .linkItem {
        padding: 0.15rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 0.16rem;
          font-weight: bold;
          margin-top: 3px;
        }
      }
    }
    .box {
      padding: 0.2rem 0.3rem;
      text-align: left;
      .codeList {
        display: flex;
        margin-top: 0.15rem;
        .codeItem {
          flex: 1;
          text-align: center;
          font-size: 10px;
          img {
            width: 80%;
          }
        }
      }
      .recordNo {
        margin-top: 0.1rem;
        color: #b8ccff;
        a {
          color: #b8ccff;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
